body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.App-bg {
  background-image: url('../public/wallpaper.jpg');
  background-size: cover;
  bottom: 0;
  filter: grayscale(100%);
  left: 0;
  opacity: 0.1;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -500;
}
